<div class="container-fluid">
  <div class="header-wrapper d-flex justify-content-between">
    <!-- Left-aligned Heading -->
    <div class="heading d-flex gap-2 align-items-center text-white fw-300" (click)="openRAMsysChatTab()">
      <img src="assets/images/chaticon.svg" alt="Chat Icon" class="chat-svg" />
      Ask RAMsys
    </div>

    <!-- Right-aligned Icons for Chat and notifications -->
    <div class="icons-wrapper">
      <nb-icon nbPrefix icon="message-square-outline" pack="eva" class="chat-icon" [matMenuTriggerFor]="chatUsersMenu">
      </nb-icon>

      <!-- Replace div with mat-menu -->
      <mat-menu #chatUsersMenu="matMenu" xPosition="before" class="chat-users-menu">
        <ng-container *ngFor="let role of objectKeys(otherUsers)">
          <div class="chat-user-role">{{ role }}</div>
          <button mat-menu-item class="chat-users-drop-down" *ngFor='let user of otherUsers[role]'
            [matTooltip]="user.name" (click)="startUserChat(user)">
            {{ getTextToShow(user.name) }}
          </button>
        </ng-container>
      </mat-menu>

      <i class="fa fa-bell" matBadge="{{ chatService.notificationsCountLimit }}" matBadgeColor="warn" aria-hidden="true"
        matBadgeSize="medium" matBadgeOverlap="true" matBadgePosition="after"
        [matBadgeHidden]="chatService.notificationsCount === 0" [matMenuTriggerFor]="menu" aria-label="menu">
      </i>
      <mat-menu #menu="matMenu" xPosition="before" class="notifications-menu">
        <button mat-menu-item class="noMessages" *ngIf="chatService.notifications.length === 0">
          {{ "HEADER.NO_MESSAGES" | translate }}
        </button>
        <button mat-menu-item class="notifications-menu-item" *ngFor='let notification of chatService.notifications'
          (click)="selectNotification(notification)">
          <span class="countBadge">{{ notification.count }}</span>
          {{ "HEADER.MESSAGES" | translate }}
          <ng-container *ngIf="notification.roomPurposeId; else elseBlock">
            {{ "HEADER.RELATED_TO" | translate }}
          </ng-container>
          <ng-template #elseBlock>
            {{ "HEADER.FROM" | translate }}
          </ng-template>
          <span class="newNotification" [matTooltip]="notification.title">
            {{ notification.title }}
          </span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>