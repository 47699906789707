import { Injectable } from "@angular/core";
import { ApiResponse } from "src/models/api-response.model";
import { ApiConstants } from "../constants/api-constants";
import { HttpClient } from "@angular/common/http";
import { Subject, Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class BranchService {

  branchesChangeListener = new Subject<any>();

  constructor(private http: HttpClient) { }

  getBranches(searchText?: string) {
    let URL = ApiConstants.URL_BRANCHES + "?detailed=true";
    if (searchText) {
      URL = URL + "&search=" + searchText;
    }
    return lastValueFrom(this.http.get<ApiResponse>(URL));
  }

  async getMapBranches(searchText?: string) {
    let URL = ApiConstants.URL_BRANCHES + "?detailed=true&type=map";
    if (searchText) {
      URL = URL + "&search=" + searchText;
    }
    let res = await lastValueFrom(this.http.get<ApiResponse>(URL));
    return res;
  }

  async getMapBranches2(searchText?: string) {
    let URL = ApiConstants.URL_BRANCHES + "?detailed=true&type=map";
    if (searchText) {
      URL = URL + "&search=" + searchText;
    }
    let res = await lastValueFrom(this.http.get<ApiResponse>(URL));
    this.branchesChangeListener.next(res);
    return res;
  }

  getBranchesChangeListener() {
    return this.branchesChangeListener.asObservable();
  }

  getAllBranches() {
    const URL = ApiConstants.URL_ALL_BRANCHES;
    return lastValueFrom(this.http.get<ApiResponse>(URL));
  }

  getBranchById(branchId: string) {
    const url = ApiConstants.URL_BRANCHES + "/" + branchId;
    return lastValueFrom(this.http.get<ApiResponse>(url));
  }

  /**
 * get list of all branches related to operator
 */
  async getOperatorBranches() {
    const url = ApiConstants.URL_BRANCHES;
    const response = await lastValueFrom(this.http.get<ApiResponse>(url));
    if (response.success === true) {
      return response.data;
    }
  }

  async getBranchUsers(branchId: string) {
    const url = ApiConstants.URL_BRANCH + "/" + branchId + '/users';
    let response = await lastValueFrom(this.http.get<ApiResponse>(url));
    if (response && response.success === true) {
      return response.data;
    } else {
      return [];
    }
  }

  async getBranchControllers(branchId: string) {
    const url = ApiConstants.URL_BRANCH + "/" + branchId + '/controllers';
    let response = await lastValueFrom(this.http.get<ApiResponse>(url));
    if (response && response.success === true) {
      return response.data;
    } else {
      return [];
    }
  }

  async setControllerValue(controllerId: string, value: string) {
    const body = {
      value: value
    };
    const url = ApiConstants.URL_BRANCH + '/controllers/' + controllerId + '/activate';
    const response = await lastValueFrom(this.http.post<ApiResponse>(url, body));
    if (response && response.success === true) {
      //
    }
    return response;
  }

  async readControllerValue(controllerId: string) {
    const url = ApiConstants.URL_BRANCH + '/controllers/' + controllerId + '/read';
    const response = await lastValueFrom(this.http.get<any>(url));
    if (response && response.success === true) {
      return response.data;
    }
    return null;
  }
}
