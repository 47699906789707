import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InsightsService } from 'src/app/services/insights.service';
import { AppSessionStorage } from 'src/app/utils/app-session-storage-utils';
import { Utility } from 'src/app/utils/app-utils';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';

interface DataPoint {
  time: string;
  count: number;
  percentage?: number;
}

interface ChartData {
  chart: string;
  today: DataPoint[];
  previous_day: DataPoint[];
}

@Component({
  selector: 'app-alarm-time-of-the-day',
  templateUrl: './alarm-time-of-the-day.component.html',
  styleUrls: ['./alarm-time-of-the-day.component.scss'],
})
export class AlarmTimeOfTheDayComponent
  implements AfterViewInit, AfterViewChecked, OnInit {
  dynamicWidth: number = 300;
  dynamicHeight: number = 180;
  private dateRangeSubscription: Subscription;
  private startDate: number | undefined;
  private endDate: number | undefined;

  data: ChartData = {
    "chart": "alarm-time-of-the-day",
    "today": [
      { "time": "00:00", "count": 0 }
    ],
    "previous_day": [
      { "time": "00:00", "count": 0 },
    ]
  };

  alarmTypes: any[] = [];
  severities: any[] = [];
  statuses: any[] = [];

  selectedStatus: string = '';
  selectedType: string = '';
  selectedSeverity: string = '';

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService, public analyticsDashboardService: AnalyticalDashboardService) { }

  async ngOnInit() {
    this.listenToDateRangeChanges();
    await this.getAlarmTypes();
    await this.getSeverities();
    await this.getStatuses();
  }

  listenToDateRangeChanges() {
    this.dateRangeSubscription = this.insightsService.currentDateRange.subscribe(dateRange => {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      this.getAlarmsAroundTheDay(this.startDate, this.endDate, this.selectedStatus, this.selectedType, this.selectedSeverity);
    });
  }

  async getAlarmsAroundTheDay(startDate?: number, endDate?: number, status?: string, type?: string, severity?: string) {
    const filters: Record<string, string> = {};

    if (status) filters.status = status;
    if (type) filters.type = type;
    if (severity) filters.severity = severity;

    let res = await this.insightsService.getAlarmsByTimeOfTheDay(startDate, endDate, filters.status, filters.type, filters.severity);
    if (res && res.success && res.data && res.data.results) {
      this.calculateChartValues(res.data.results);
    } else { }
  }

  private calculateChartValues(values: any) {
    const user = AppSessionStorage.getUser();
    const timeZone = user.timeZone;

    let formattedData = {
      'previous_day': this.formatTimestampData(values['previousPeriodAlarmsCount'], timeZone),
      'today': this.formatTimestampData(values['currentPeriodAlarmsCount'], timeZone, true)
    };

    this.data = {
      "chart": this.data.chart,
      "today": formattedData.today,
      "previous_day": formattedData.previous_day
    };

    this.cdr.detectChanges();
  }

  private formatTimestampData(data: any, timeZone: string, includePercentage: boolean = false) {
    let dataByHour = new Map<string, DataPoint>();

    // First, we willcreate entries for all 24 hours with zero counts
    for (let hour = 0; hour < 24; hour++) {
      const hourStr = hour.toString().padStart(2, '0') + ':00';
      dataByHour.set(hourStr, {
        time: hourStr,
        count: 0,
        ...(includePercentage && { percentage: 0 })
      });
    }

    // Then, we will fill in the actual data from backend
    for (const timestamp of Object.keys(data)) {
      const date = new Date(parseInt(timestamp));
      const hourStr = date.toLocaleString('en-US', {
        timeZone: timeZone,
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
      });

      dataByHour.set(hourStr, {
        time: hourStr,
        count: data[timestamp].count,
        ...(includePercentage && { percentage: data[timestamp].percentage })
      });
    }

    // Convert map to sorted array
    return Array.from(dataByHour.values())
      .sort((a, b) => a.time.localeCompare(b.time));
  }

  ngAfterViewInit(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  private updateSvgDimensions() {
    const parentWidth = document
      .querySelector('#line-alarm-time-of-the-day')
      ?.getBoundingClientRect().width;
    if (parentWidth) {
      this.dynamicWidth = parentWidth ? parentWidth - 10 : 300;
    }

    this.dynamicHeight = window.innerHeight > 1300 ? 300
      : window.innerHeight >= 1080 ? 225
        : window.innerHeight >= 1024 ? 173
          : window.innerHeight == 905 ? 143
            : window.innerHeight >= 934 ? 195
              : 258;
  }

  async getAlarmTypes() {
    this.alarmTypes = await this.analyticsDashboardService.getAlarmsTypes();
  }

  async getSeverities() {
    this.severities = await this.analyticsDashboardService.getAlarmsSeverityTypes();
  }

  async getStatuses() {
    this.statuses = await this.analyticsDashboardService.getAlarmsStatusTypes();
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.dateRangeSubscription);
  }

  onFilterChange(): void {
    this.getAlarmsAroundTheDay(this.startDate, this.endDate, this.selectedStatus, this.selectedType, this.selectedSeverity);
  }
}
