import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Alarm } from 'src/models/alarm.model';
import { AlarmService } from '../services/alarm.service';
import { Utility } from '../utils/app-utils';
import { Subscription } from 'rxjs';
import { AlarmStatus } from '../enums/alarm-status.enum';
import { WorkflowService } from '../services/workflow.service';
import { AppConstants } from '../constants/app-constants';

@Component({
  selector: 'app-alarm-progress',
  templateUrl: './alarm-progress.component.html',
  styleUrls: ['./alarm-progress.component.scss']
})
export class AlarmProgressComponent implements OnInit {
  @Input() alarm: Alarm;
  selectedAlarm: Alarm;
  alarmStatusSub: Subscription;

  steps = [
    { number: '1', icon: 'fa-hourglass-end', label: AlarmStatus.OPEN, time: '', completed: false, strokeColor: '#ccc' },
    { number: '2', icon: 'fa-exclamation', label: AlarmStatus.ACKNOWLEDGED, time: '', completed: false, strokeColor: '#ccc' },
    { number: '3', icon: 'fa-check', label: AlarmStatus.VERIFIED, time: '', completed: false, strokeColor: '#ccc' },
    { number: '4', icon: 'fa-arrow-up', label: AlarmStatus.ESCALATED, time: '', completed: false, strokeColor: '#ccc' }
  ];

  actions: any[] = [];
  currentActionIndex = 0;
  currentCharIndex = 0;
  animateNewActions = false;
  newActionSub: Subscription;
  @ViewChild('actionsList', { static: false }) private actionsList: ElementRef;

  constructor(private cdr: ChangeDetectorRef, public alarmService: AlarmService, public workflowService: WorkflowService) { }

  async ngOnInit() {
    this.alarmStatusChangeListener();

    this.newActionSub = this.workflowService.getNewActionListener().subscribe((action: any) => {
      const newAction = {
        text: action,
        completed: false,
        displayedText: ''
      };
      this.actions.push(newAction);
      this.animateNewActions = true;
      this.animateActions();
      this.scrollToBottom();
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.alarm.currentValue) {
      this.selectedAlarm = changes.alarm.currentValue;
      this.updateSteps();
      // manually trigger change detection
      // this.cdr.detectChanges();

      await this.workflowService.fetchActionHistory(this.alarm.id);
      let actionsHistory = this.workflowService.actionHistory;
      this.actions = actionsHistory.map(action => ({
        text: action.action,
        completed: true,
        displayedText: action.action
      }));

      this.currentActionIndex = this.actions.length;
      this.currentCharIndex = 0;
      this.animateNewActions = false;
      this.scrollToBottom();
    }
  }

  alarmStatusChangeListener() {
    this.alarmStatusSub = this.alarmService.getChangeAlarmStatusNotification().subscribe((updatedAlarm) => {
      if (this.selectedAlarm.id === updatedAlarm._id) {
        this.selectedAlarm.actionTime = updatedAlarm?.actionTime;
        this.selectedAlarm.status = updatedAlarm.status;
        this.updateSteps();
      }
    });
  }

  updateSteps() {
    // Reset the steps to the initial state
    this.steps = [
      { number: '1', icon: 'fa-hourglass-end', label: AlarmStatus.OPEN, time: '', completed: true, strokeColor: '#fabb74' }, // Open step is always completed
      { number: '2', icon: 'fa-exclamation', label: AlarmStatus.ACKNOWLEDGED, time: '', completed: false, strokeColor: '#ccc' },
      { number: '3', icon: 'fa-check', label: AlarmStatus.VERIFIED, time: '', completed: false, strokeColor: '#ccc' },
      { number: '4', icon: 'fa-arrow-up', label: AlarmStatus.ESCALATED, time: '', completed: false, strokeColor: '#ccc' }
    ];

    if (!this.selectedAlarm || !this.selectedAlarm.actionTime) {
      return; // No actions or times available
    }

    const { activationTime, actionTime } = this.selectedAlarm;

    let previousTime = activationTime;

    this.steps.forEach((step, index) => {
      let currentStepTime: number | null = null;

      switch (step.label) {
        case AlarmStatus.ACKNOWLEDGED:
          currentStepTime = actionTime.acknowledged || null;
          step.time = currentStepTime ? this.formatTimeDifference(currentStepTime - previousTime) : '';
          break;

        case AlarmStatus.VERIFIED:
          currentStepTime = actionTime.verificationTime || null;
          if (currentStepTime) {
            const acknowledgedTime = actionTime.acknowledged || previousTime;
            step.time = this.formatTimeDifference(currentStepTime - acknowledgedTime);
          } else {
            step.time = '';
          }
          break;

        case AlarmStatus.ESCALATED:
          currentStepTime = actionTime.escalationTime || null;
          if (currentStepTime) {
            const previousTimePoint = actionTime.verificationTime || actionTime.acknowledged || previousTime;
            // Calculate the time difference using Math.abs to handle any order
            const timeDiff = Math.abs(currentStepTime - previousTimePoint);
            step.time = this.formatTimeDifference(timeDiff);
          } else {
            step.time = '';
          }
          break;

        case AlarmStatus.RESOLVED:
        case AlarmStatus.FALSE:
          currentStepTime = actionTime.reslovingTime || null;
          if (currentStepTime) {
            const escalationTime = actionTime.escalationTime || actionTime.verificationTime || actionTime.acknowledged || activationTime;
            step.time = this.formatTimeDifference(currentStepTime - escalationTime);
          } else {
            step.time = '';
          }
          break;

        default:
          step.time = '';
          break;
      }

      // Highlight the step only if it has a valid time
      if (step.time) {
        step.completed = true;
        step.strokeColor = '#fabb74';
        previousTime = currentStepTime || previousTime;
      } else {
        step.strokeColor = '#ccc'; // Keep default stroke color if no time is available
      }

      // Ensure the Open step always has a stroke color
      if (step.label === AlarmStatus.OPEN) {
        step.strokeColor = '#fabb74';
      }
    });

    // Conditionally add "Resolved" or "False"
    if (this.selectedAlarm.status === AlarmStatus.RESOLVED) {
      const resolvedStep = { number: '5', icon: 'fa-check-circle', label: AlarmStatus.RESOLVED, time: '', completed: false, strokeColor: '#ccc' };
      const resolvingTime = actionTime.reslovingTime || null;
      if (resolvingTime) {
        const escalationTime = actionTime.escalationTime || actionTime.verificationTime || actionTime.acknowledged || activationTime;
        resolvedStep.time = this.formatTimeDifference(resolvingTime - escalationTime);
        resolvedStep.completed = true;
        resolvedStep.strokeColor = '#fabb74';
      }
      this.steps.push(resolvedStep);
    } else {
      const falseStep = { number: '5', icon: 'fa-close', label: AlarmStatus.FALSE, time: '', completed: false, strokeColor: '#ccc' };
      const resolvingTime = actionTime.reslovingTime || null;
      if (resolvingTime) {
        const escalationTime = actionTime.escalationTime || actionTime.verificationTime || actionTime.acknowledged || activationTime;
        falseStep.time = this.formatTimeDifference(resolvingTime - escalationTime);
        falseStep.completed = true;
        falseStep.strokeColor = '#fabb74';
      }
      this.steps.push(falseStep);
    }
  }

  formatTimeDifference(timeDifference: number): string {
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    let formattedTime = '';

    if (days > 0) {
      formattedTime += `${days} day${days > 1 ? 's' : ''} `;
    }

    if (hours > 0 || days > 0) {
      formattedTime += `${hours} hour${hours > 1 ? 's' : ''} `;
    }

    if (minutes > 0 || hours > 0 || days > 0) {
      formattedTime += `${minutes} min `;
    }

    if (seconds > 0 || (days === 0 && hours === 0 && minutes === 0)) {
      formattedTime += `${seconds} sec`;
    }

    return formattedTime.trim();
  }

  getXCoordinate(index: number): string {
    const padding = 5; // percentage padding from edges
    const availableWidth = 100 - (2 * padding);
    const step = availableWidth / (this.steps.length - 1);
    return `${padding + (step * index)}%`;
  }

  getIconXCoordinate(index: number): string {
    const xPos = parseFloat(this.getXCoordinate(index));
    return `${xPos}%`;
  }

  getIconYCoordinate(index: number): string {
    return index % 2 === 0 ? '90' : '50';
  }

  getYCoordinate(index: number): string {
    return `${index % 2 === 0 ? 90 : 50}`; // Adjusting Y positions for the circles
  }

  getLabelYCoordinate(index: number): string {
    return `${index % 2 === 0 ? 50 : 100}`; // Position label above or below the circle
  }

  getTimeYCoordinate(index: number): string {
    return `${index % 2 === 0 ? 30 : 120}`; // Position time below the label
  }

  getStrokeColor(index: number): string {
    // Check if the last step is completed
    const lastStep = this.steps[this.steps.length - 1];
    if (lastStep.completed) {
      return '#fabb74'; // Color all lines if last step is completed
    }

    // Find the last completed step index
    const lastCompletedIndex = this.steps.findIndex(step => !step.completed) - 2;
    return index <= lastCompletedIndex ? '#fabb74' : '#ccc';
  }

  ngOnDestroy() {
    this.alarmStatusSub = Utility.Unsubscribe(this.alarmStatusSub);
  }

  animateActions() {
    if (!this.animateNewActions) return; // Exit if no animation is needed

    if (this.currentActionIndex < this.actions.length) {
      const currentAction = this.actions[this.currentActionIndex];
      if (this.currentCharIndex < currentAction.text.length) {
        currentAction.displayedText += currentAction.text[this.currentCharIndex];
        this.currentCharIndex++;
        setTimeout(() => {
          this.animateActions();
        }, 50);
      } else {
        currentAction.completed = true;
        this.currentActionIndex++;
        this.currentCharIndex = 0;
        if (this.currentActionIndex < this.actions.length) {
          setTimeout(() => {
            this.animateActions();
          }, 1500);
        }
      }
    }
  }

  // Method to scroll to the bottom of the actions list
  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.actionsList && this.actionsList.nativeElement) {
        this.actionsList.nativeElement.scrollTop = this.actionsList.nativeElement.scrollHeight;
      }
    }, 0);
  }

}
