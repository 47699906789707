<div class="branch-controls-container">
  <div class="branch-controls-header">
    {{ "BRANCH_CONTROLS.HEADER" | translate }}
  </div>

  <div class="sites-stats">
    <app-sites-table [displayedColumns]="displayedColumns"></app-sites-table>
  </div>

  <div class="controls">
    <div class="dropdown-container">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'BRANCH_CONTROLS.SELECT_ZONE' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedZone" (selectionChange)="onZoneChange($event.value)">
          <mat-option *ngFor="let zone of zones" [value]="zone.value">
            {{zone.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{ 'BRANCH_CONTROLS.SELECT_CONTROL' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedControl" (selectionChange)="onControlChange($event.value)"
          [disabled]="!selectedZone">
          <mat-option *ngFor="let control of availableControls" [value]="control._id">
            {{control.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="control-interface" *ngIf="selectedControl">
      <ng-container *ngIf="getSelectedControlType() as controlType">
        <!-- Digital Control -->
        <div class="control-item" *ngIf="controlType === 'switch'">
          <label class="ios-toggle">
            <input type="checkbox" [(ngModel)]="digitalValue" (change)="onDigitalChange($event)">
            <span class="slider"></span>
          </label>
          <div>
            Current Status: <span [class.active]="digitalValue">{{ digitalValue ? 'ON' : 'OFF' }}</span>
          </div>
        </div>

        <!-- Analog Control -->
        <div class="control-item" *ngIf="controlType === 'analog'">
          <mat-slider thumbLabel min="0" max="100" step="1" [(ngModel)]="analogValue" (change)="onAnalogChange($event)">
          </mat-slider>
          <div>Value: <span class="analog-value">{{analogValue}}</span></div>
        </div>
      </ng-container>
    </div>
  </div>

</div>