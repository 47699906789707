import { Component, OnInit } from '@angular/core';
import { ConfidenceMonitoringService } from 'src/app/services/confidence-monitoring.service';
import { Subscription } from 'rxjs';
import { BranchService } from '../services/branch.service';

interface Controller {
  _id: string;
  name: string;
  location: {
    x: string;
    y: string;
  };
  zone: string;
  branch: string;
  dataType: 'switch' | 'analog';
}

interface ZoneControllers {
  name: string;
  controllers: Controller[];
}

interface ZoneControllersMap {
  [zoneId: string]: ZoneControllers;
}

@Component({
  selector: 'app-branch-controls',
  templateUrl: './branch-controls.component.html',
  styleUrls: ['./branch-controls.component.scss']
})

export class BranchControlsComponent implements OnInit {
  displayedColumns = [
    'Sites',
    'Region',
    'Status'
  ];

  public getSitesChangedSubscriper: Subscription;
  selectedZone: string | null = null;
  selectedControl: string | null = null;
  availableControls: Controller[] = [];
  zones: { value: string; name: string; }[] = [];
  digitalValue = false;
  analogValue = 0;
  currentBranchId: string = '';
  private allControllers: ZoneControllersMap = {};

  constructor(
    public confidenceMonitoringService: ConfidenceMonitoringService,
    public branchService: BranchService) { }

  ngOnInit() {
    this.getSitesChangedSubscriper = this.confidenceMonitoringService.getSitesChangedNotification().subscribe(async (branch: any) => {
      this.currentBranchId = branch.id;
      await this.getBranchControllers(branch.id);
    });

    this.availableControls = [];
  }

  async getBranchControllers(branchId: string) {
    try {
      this.allControllers = await this.branchService.getBranchControllers(branchId);
      this.zones = Object.entries(this.allControllers).map(([zoneId, zoneData]) => ({
        value: zoneId,
        name: zoneData.name
      }));

      // Clear available controls until zone is selected
      this.availableControls = [];
      this.selectedZone = null;
      this.selectedControl = null;
    } catch (error) {
      console.error('Failed to fetch controllers:', error);
      this.zones = [];
      this.availableControls = [];
      this.allControllers = {};
    }
  }

  ngOnDestroy() {
    this.getSitesChangedSubscriper.unsubscribe();
  }

  onZoneChange(zoneValue: string) {
    this.selectedZone = zoneValue;
    this.availableControls = this.allControllers[zoneValue]?.controllers || [];
    this.selectedControl = null;
  }

  async onControlChange(controlId: string) {
    this.selectedControl = controlId;
    let value = await this.branchService.readControllerValue(controlId);
    if (value === null) {
      this.digitalValue = false;
      this.analogValue = 0;
      return;
    }

    const controlType = this.getSelectedControlType();
    if (controlType === 'switch') {
      this.digitalValue = value;
    } else if (controlType === 'analog') {
      this.analogValue = parseFloat(value);
    }
  }

  getSelectedControlType(): 'analog' | 'switch' | null {
    if (!this.selectedControl) return null;

    const selectedController = this.availableControls.find(
      control => control._id === this.selectedControl
    );

    return selectedController?.dataType || null;
  }

  async onDigitalChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.digitalValue = checkbox.checked;
    await this.branchService.setControllerValue(this.selectedControl, this.digitalValue.toString());
  }

  async onAnalogChange(event: any) {
    await this.branchService.setControllerValue(this.selectedControl, this.analogValue.toString());
  }

}
