import { AppConstants } from '../constants/app-constants';

export class ChartResponsiveOptionUtil {

	/**
	 * called to get alarms chart options for responsive screen
	 */
	public static alarmsChartOptions(resolutionOfScreen: any) {
		// console.log(resolutionOfScreen);
		const options: any = {};
		switch (resolutionOfScreen) {
			case AppConstants.WUHD:
				options.width = 1500;
				options.spacingLeft = 180;
				options.spacingBottom = 320;
				options.fontSize = '47.6279px';
				options.symbolHeight = 40;
				options.titleFont = '47.6279px';
				options.legendY = 210;
				options.lineHeight = '50px';
				options.itemMarginBottom = 40;
				break;
			case AppConstants.NORMAL:
				options.width = 1100;
				options.spacingLeft = 165;
				options.spacingBottom = 415;
				options.fontSize = '32px';
				options.symbolHeight = 30;
				options.titleFont = '32px';
				options.legendY = 150;
				options.lineHeight = '30px';
				options.itemMarginBottom = 20;
				break;
			case AppConstants.HALF_WFHD:
				options.width = 1100;
				options.spacingLeft = 165;
				options.spacingBottom = 430;
				options.fontSize = '32px';
				options.symbolHeight = 30;
				options.titleFont = '32px';
				options.legendY = 130;
				options.lineHeight = '30px';
				options.itemMarginBottom = 20;
				break;
			case AppConstants.WFHD:
				options.width = 800;
				options.spacingLeft = 135;
				options.spacingBottom = 470;
				options.fontSize = '23.814px';
				options.symbolHeight = 25;
				options.titleFont = '23.814px';
				options.legendY = 110;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.HALF_WUHD:
				options.width = 800;
				options.spacingLeft = 135;
				options.spacingBottom = 490;
				options.fontSize = '23.814px';
				options.symbolHeight = 25;
				options.titleFont = '23.814px';
				options.legendY = 100;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.FULL_HD:
				options.width = 600;
				options.spacingLeft = 95;
				options.spacingBottom = 490;
				options.fontSize = '20px';
				options.symbolHeight = 18;
				options.titleFont = '20px';
				options.legendY = 100;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.WUXGA:
				options.width = 550;
				options.spacingLeft = 90;
				options.spacingBottom = 450;
				options.fontSize = '20px';
				options.symbolHeight = 20;
				options.titleFont = '24px';
				options.legendY = 100;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			default:
				options.width = 1500;
				options.spacingLeft = 135;
				options.spacingBottom = 320;
				options.fontSize = '47.6279px';
				options.symbolHeight = 40;
				options.titleFont = '47.6279px';
				options.legendY = 190;
				options.lineHeight = '50px';
				options.itemMarginBottom = 40;
		}
		return options;
	}

	/**
	 * called to get alarms option object
	 */
	public static getAlarmsOptions(options: any, titleTooltip: string) {
		return {
			width: options.width,
			spacingLeft: options.spacingLeft,
			spacingBottom: options.spacingBottom,
			fontSize: options.fontSize,
			symbolHeight: options.symbolHeight,
			titleFont: options.titleFont,
			legendY: options.legendY,
			lineHeight: options.lineHeight,
			itemMarginBottom: options.itemMarginBottom,
			titleTooltip
		};
	}

	/**
	 * called to get operators chart options for responsive screen
	 */
	public static operatorChartsOptions(resolutionOfScreen: any, chartDataLength: number) {
		const options: any = {};
		switch (resolutionOfScreen) {
			case AppConstants.WUHD:
				options.width = 1500;
				options.spacingLeft = 160;
				options.spacingBottom = 320;
				options.fontSize = '47.6279px';
				options.symbolHeight = 40;
				options.titleFont = '47.6279px';
				options.legendHight = '200px';
				options.legendY = 350;
				options.lineHeight = '50px';
				options.itemMarginBottom = 40;
				break;
			case AppConstants.NORMAL:
				options.width = 1100;
				options.spacingLeft = 145;
				options.spacingBottom = 415;
				options.fontSize = '32px';
				options.symbolHeight = 30;
				options.titleFont = '32px';
				if (chartDataLength === 1) {
					options.legendY = 150;
				} else {
					options.legendY = 190;
				}
				options.lineHeight = '30px';
				options.itemMarginBottom = 20;
				options.legendHight = '100px';
				break;
			case AppConstants.HALF_WFHD:
				options.width = 1100;
				options.spacingLeft = 145;
				options.spacingBottom = 430;
				options.fontSize = '32px';
				options.symbolHeight = 30;
				options.titleFont = '32px';
				options.legendY = 145;
				options.lineHeight = '30px';
				options.itemMarginBottom = 20;
				break;
			case AppConstants.WFHD:
				options.width = 800;
				options.spacingLeft = 100;
				options.spacingBottom = 470;
				options.fontSize = '23.814px';
				options.symbolHeight = 25;
				options.titleFont = '23.814px';
				options.legendY = 105;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.HALF_WUHD:
				options.width = 800;
				options.spacingLeft = 100;
				options.spacingBottom = 490;
				options.fontSize = '23.814px';
				options.symbolHeight = 25;
				options.titleFont = '23.814px';
				options.legendY = 90;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.FULL_HD:
				options.width = 600;
				options.spacingLeft = 60;
				options.spacingBottom = 490;
				options.fontSize = '20px';
				options.symbolHeight = 18;
				options.titleFont = '20px';
				options.legendY = 170;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.WUXGA:
				options.width = 600;
				options.spacingLeft = 100;
				options.spacingBottom = 480;
				options.fontSize = '20px';
				options.symbolHeight = 18;
				options.titleFont = '20px';
				options.legendY = 200;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			default:
				options.width = 1500;
				options.spacingLeft = 750;
				options.spacingBottom = 320;
				options.fontSize = '47.6279px';
				options.symbolHeight = 40;
				options.titleFont = '47.6279px';
				options.legendY = 190;
				options.lineHeight = '50px';
				options.itemMarginBottom = 40;
		}
		return options;
	}

	/**
	 * called to get operators option object
	 */
	public static getOperatorOptions(options: any, legendData: any) {
		return {
			width: options.width,
			spacingLeft: options.spacingLeft,
			shouldShowLegend: true,
			shouldCountInTooltip: true,
			spacingBottom: options.spacingBottom,
			fontSize: options.fontSize,
			symbolHeight: 0.001,
			titleFont: options.titleFont,
			legendY: options.legendY,
			lineHeight: options.lineHeight,
			itemMarginBottom: options.itemMarginBottom,
			customLegend: true,
			legendData: legendData,
			legendHight: options.legendHight
		};

	}

	/**
	 * called to get sites chart options for responsive screen
	 */
	public static sitesChartOptions(resolutionOfScreen: any, chartDataLength: number) {
		const options: any = {};
		switch (resolutionOfScreen) {
			case AppConstants.WUHD:
				options.width = 1200;
				options.spacingLeft = 100;
				options.spacingBottom = 325;
				options.fontSize = '47.6279px';
				options.symbolHeight = 40;
				options.titleFont = '47.6279px';
				options.legendY = 250;
				options.lineHeight = '50px';
				options.itemMarginBottom = 40;
				break;
			case AppConstants.NORMAL:
				options.width = 760;
				options.spacingLeft = 80;
				options.spacingBottom = 430;
				options.fontSize = '32px';
				options.symbolHeight = 30;
				options.titleFont = '32px';
				if (chartDataLength === 1) {
					options.legendY = 150;
				} else {
					options.legendY = 190;
				}
				options.lineHeight = '30px';
				options.itemMarginBottom = 20;
				options.legendHight = '100px';
				break;
			case AppConstants.HALF_WFHD:
				options.width = 760;
				options.spacingLeft = 80;
				options.spacingBottom = 430;
				options.fontSize = '32px';
				options.symbolHeight = 30;
				options.titleFont = '32px';
				options.legendY = 150;
				options.lineHeight = '25px';
				options.itemMarginBottom = 20;
				break;
			case AppConstants.WFHD:
				options.width = 650;
				options.spacingLeft = 50;
				options.spacingBottom = 470;
				options.fontSize = '23.814px';
				options.symbolHeight = 25;
				options.titleFont = '23.814px';
				options.legendY = 105;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.HALF_WUHD:
				options.width = 600;
				options.spacingLeft = 50;
				options.spacingBottom = 490;
				options.fontSize = '23.814px';
				options.symbolHeight = 25;
				options.titleFont = '23.814px';
				options.legendY = 90;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.FULL_HD:
				options.width = 500;
				options.spacingLeft = 50;
				options.spacingBottom = 490;
				options.fontSize = '20px';
				options.symbolHeight = 18;
				options.titleFont = '20px';
				options.legendY = 170;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.WUXGA:
				options.width = 470;
				options.spacingLeft = 50;
				options.spacingBottom = 420;
				options.fontSize = '20px';
				options.symbolHeight = 18;
				options.titleFont = '20px';
				options.legendY = 230;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			default:
				options.width = 1100;
				options.spacingLeft = 400;
				options.spacingBottom = 320;
				options.fontSize = '47.6279px';
				options.symbolHeight = 40;
				options.titleFont = '47.6279px';
				options.legendY = 190;
				options.lineHeight = '50px';
				options.itemMarginBottom = 40;
		}
		return options;
	}

	/**
	 * called to get sites option object
	 */
	public static getSitesOptions(options: any, legendData: any) {
		return {
			width: options.width,
			spacingLeft: options.spacingLeft,
			spacingBottom: options.spacingBottom,
			fontSize: options.fontSize,
			shouldShowLegend: true,
			symbolHeight: 0.001,
			titleFont: options.titleFont,
			legendY: options.legendY,
			lineHeight: options.lineHeight,
			itemMarginBottom: options.itemMarginBottom,
			shouldCountInTooltip: true,
			customLegend: true,
			legendData: legendData,
			legendHight: options.legendHight
		};
	}

	/**
	 * called to get sites priority chart options for responsive screen
	 */
	public static sitesPriorityChartOptions(resolutionOfScreen: any) {
		const options: any = {};
		switch (resolutionOfScreen) {
			case AppConstants.WUHD:
				options.width = 1200;
				options.spacingLeft = 170;
				options.spacingBottom = 320;
				options.fontSize = '47.6279px';
				options.symbolHeight = 40;
				options.titleFont = '47.6279px';
				options.legendY = 220;
				options.lineHeight = '50px';
				options.itemMarginBottom = 40;
				break;
			case AppConstants.NORMAL:
				options.width = 760;
				options.spacingLeft = 100;
				options.spacingBottom = 430;
				options.fontSize = '32px';
				options.symbolHeight = 30;
				options.titleFont = '32px';
				options.legendY = 135;
				options.lineHeight = '30px';
				options.itemMarginBottom = 20;
				break;
			case AppConstants.HALF_WFHD:
				options.width = 760;
				options.spacingLeft = 105;
				options.spacingBottom = 430;
				options.fontSize = '32px';
				options.symbolHeight = 30;
				options.titleFont = '32px';
				options.legendY = 150;
				options.lineHeight = '30px';
				options.itemMarginBottom = 20;
				break;
			case AppConstants.WFHD:
				options.width = 650;
				options.spacingLeft = 50;
				options.spacingBottom = 470;
				options.fontSize = '23.814px';
				options.symbolHeight = 25;
				options.titleFont = '23.814px';
				options.legendY = 100;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.HALF_WUHD:
				options.width = 600;
				options.spacingLeft = 50;
				options.spacingBottom = 490;
				options.fontSize = '23.814px';
				options.symbolHeight = 25;
				options.titleFont = '23.814px';
				options.legendY = 100;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.FULL_HD:
				options.width = 500;
				options.spacingLeft = 50;
				options.spacingBottom = 490;
				options.fontSize = '20px';
				options.symbolHeight = 18;
				options.titleFont = '20px';
				options.legendY = 100;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			case AppConstants.WUXGA:
				options.width = 420;
				options.spacingLeft = 100;
				options.spacingBottom = 420;
				options.fontSize = '20px';
				options.symbolHeight = 18;
				options.titleFont = '20px';
				options.legendY = 150;
				options.lineHeight = '18px';
				options.itemMarginBottom = 5;
				break;
			default:
				options.width = 1100;
				options.spacingLeft = 100;
				options.spacingBottom = 320;
				options.fontSize = '47.6279px';
				options.symbolHeight = 40;
				options.titleFont = '47.6279px';
				options.legendY = 190;
				options.lineHeight = '50px';
				options.itemMarginBottom = 40;
		}
		return options;
	}

	/**
	 * called to get sites priority option object
	 */
	public static getSitesPriorityOptions(options: any) {
		return {
			width: options.width,
			spacingLeft: options.spacingLeft,
			spacingBottom: options.spacingBottom,
			fontSize: options.fontSize,
			symbolHeight: options.symbolHeight,
			titleFont: options.titleFont,
			legendY: options.legendY,
			lineHeight: options.lineHeight,
			itemMarginBottom: options.itemMarginBottom
		};
	}

}
