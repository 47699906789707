<div class="progress-container">
  <p class="progress-header">
    Alarm Progress
  </p>

  <svg viewBox="0 0 1100 150" preserveAspectRatio="xMidYMid meet">
    <!-- Lines connecting circles -->
    <g *ngFor="let step of steps; let i = index">
      <line *ngIf="i < steps.length - 1"
        [attr.x1]="getXCoordinate(i)"
        [attr.y1]="getYCoordinate(i)"
        [attr.x2]="getXCoordinate(i + 1)"
        [attr.y2]="getYCoordinate(i + 1)"
        [attr.stroke]="getStrokeColor(i)"
        stroke-width="6"/>
    </g>

    <!-- Circles and labels -->
    <g *ngFor="let step of steps; let i = index">
      <!-- Circle -->
      <circle
        [attr.cx]="getXCoordinate(i)"
        [attr.cy]="getYCoordinate(i)"
        r="26"
        [attr.stroke]="step.strokeColor"
        stroke-width="6"/>

      <!-- Icon -->
      <foreignObject 
        [attr.x]="getIconXCoordinate(i)"
        [attr.y]="getIconYCoordinate(i)"
        width="70"
        height="70"
        [attr.transform]="'translate(-35, -35)'"
      >
        <div xmlns="http://www.w3.org/1999/xhtml"
             style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
          <i [class]="'fa ' + step.icon" 
             [style.color]="step.completed ? '#000' : '#ccc'"
             style="font-size: 22px;">
          </i>
        </div>
      </foreignObject>

      <!-- Label -->
      <text
        [attr.x]="getXCoordinate(i)"
        [attr.y]="getLabelYCoordinate(i)"
        text-anchor="middle"
        class="step-label">
        {{step.label}}
      </text>

      <!-- Time -->
      <text
        [attr.x]="getXCoordinate(i)"
        [attr.y]="getTimeYCoordinate(i)"
        text-anchor="middle"
        class="step-time">
        {{step.time}}
      </text>
    </g>
  </svg>

  <p class="actions-header">
    Actions
  </p>

  <div class="actions">
    <ul #actionsList>
      <div *ngIf="actions.length === 0" class="no-actions">
        {{'ALARM_HISTORY.NO_DATA' | translate}}
      </div>
      <li *ngFor="let action of actions" class="typewriter">
        <span *ngIf="action.completed" class="checkmark">&#10004;</span>{{ action.displayedText }}
      </li>
    </ul>
  </div>
  
</div>
