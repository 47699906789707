import { Component, Input, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';

interface Column {
  label: string;
  key: string;
}

interface TableData {
  columns: Column[];
  values: { [key: string]: any }[];
}
@Component({
  selector: 'app-operator-ranking-table',
  templateUrl: './operator-ranking-table.component.html',
  styleUrls: ['./operator-ranking-table.component.scss'],
})
export class OperatorRankingTableComponent
  implements AfterViewInit, AfterViewChecked {
  constructor(private cdr: ChangeDetectorRef) { }
  dynamicMinHeight: number = 275;

  @Input() data: TableData = { columns: [], values: [] };

  ngAfterViewInit(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  private updateSvgDimensions() {
    this.dynamicMinHeight = 
      window.innerHeight > 1300 ? 400 :
      window.innerHeight >= 1080 ? 300 :
      window.innerHeight >= 1024 ? 298 :
      window.innerHeight === 905 ? 250 :
      285;
  }
}
